.inform {
    padding: 110px 30px;
    background: url(../../Assets/Images/Home-Info.webp) no-repeat center center fixed;
    background-size: cover;
    color: var(--primary-color);
}

.inform .parent-items {
    grid-template-columns: repeat(4,1fr);
}

.card-icon {
    font-size: 72px;
}

.card-number {
    margin: 10px 0;
    font: lighter 60px 'raleway-medium';
}

.counter-card .title-sm {
    text-transform: uppercase;
    color: #fff;
}

/* Responsive */
@media (max-width: 1024px) {
    .inform {
        padding: 70px 0;
    }

    .inform .parent-items {
        row-gap: 50px;
    }
}