.header {
    height: 100px;
    background-color: #fff;
    box-shadow: 0px 5px 5px 0px #0000001a;
    position: fixed;
    inset: 0;
    z-index: 99;
    transition-duration: 0.25s;
}

.header-secondary {
    height: 65px;
    background-color: #fff;
}

.hide-header {
    transform: translateY(-100%);
}

.header .container {
    padding: 0 4%;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 100px;
}

/* website logo */
.website-logo {
    height: 104%;
}

/* menu */
.menu {
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    column-gap: 4%;
    transition-duration: 0.25s;
}

.menu-item {
    position: relative;
}

.menu-item::after {
    content: '';
    border: 10px solid transparent;
    border-top: 8px solid #fff;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%,-100%);
    transition-duration: 0.25s;
}

.menu-item:last-child::after {
    visibility: hidden;
}

.menu-item:has(.submenu):hover::after,
.current-page::after {
    transform: translateX(-50%);
}

.menu-item-link {
    height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--secondary-color);
    transition-duration: 0.25s;
}

/* sub menu */
.submenu {
    visibility: hidden;
    opacity: 0;
    width: 225px;
    background-color: #fff;
    box-shadow: 0 0 10px #0000004d;
    position: absolute;
    top: calc(100% + 30px);
    left: 0;
    transition: 0.5s 0.15s;
}

.menu-item:hover .submenu {
    visibility: visible;
    opacity: 1;
}

.submenu::before {
    content: '';
    border: 10px solid transparent;
    border-bottom: 8px solid #fff;
    position: absolute;
    bottom: 100%;
    left: 17px;
}

.submenu-item {
    border-top: 1px solid #e2e2e2;
}

.submenu-item:first-child {
    border: unset;
}

.submenu-link {
    width: 100%;
    padding: 20px;
    letter-spacing: 1px;
    text-transform: capitalize;
    text-align: left;
    font-size: 14px;
    color: var(--secondary-color);
    transition-duration: 0.25s;
}

.menu-item-link:hover,
.submenu-link:hover,
.current-page {
    color: var(--primary-color);
}

.submenu-btn {
    display: none;
}

/* menu hamburger */
.menu-hamburger {
    display: none;
}

/* Responsive */
@media (max-width: 1024px) {
    .menu {
        width: 100%;
        flex-direction: column;
        justify-content: unset;
        position: absolute;
        top: 100%;
        left: 0;
        visibility: hidden;
        opacity: 0;
    }

    .show-menu {
        visibility: visible ;
        opacity: 1 ;
    }
    
    .menu-item::after {
        display: none;
    }

    .menu-item:last-child {
        box-shadow: 0px 20px 20px #00000018;
    }
    
    .menu-item-link {
        height: auto;
        padding: 20px;
        background-color: #fff;
        border-top: 1px solid #00000020;
        color: var(--secondary-color);
    }

    .menu-item-link .btn {
        all: unset;
    }

    .menu-item-link:hover,
    .current-page {
        background-color: var(--primary-color);
        border-color: #e2e2e2;
        color: #fff;
    }
    
    .submenu {
        width: 100%;
        height: 0;
        box-shadow: unset;
        position: relative;
        top: 0;
        overflow-y: hidden;
        transition-delay: 0s;
    }

    .show-submenu {
        height: calc(4 * 62px);
        visibility: visible;
        opacity: 1;
    }

    .submenu::before {
        display: none;
    }

    .submenu-item:first-child {
        border-top: 1px solid #e2e2e2;
    }
    
    .submenu-link:hover {
        background-color: var(--primary-color);
        color: #fff;
    }

    .submenu-btn {
        display: block;
        padding: 20px;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 24px;
        transition-duration: 0.25s;
    }

    .submenu-btn:hover {
        background-color: var(--primary-color);
        color: #fff;
    }

    .menu-hamburger {
        display: block;
        cursor: pointer;
        font-size: 38px;
        color: var(--primary-color);
        transition-duration: 0.25s;
    }

    .icon-close {
        padding-top: 4px;
        transform: rotate(-180deg);
    }
}

@media (max-width:600px) {
    .header {
        height: 80px;
    }
}